import {usePage} from "@inertiajs/vue3";

export default {
    methods: {
        /**
         * Translate the given key.
         */
        __(key, replace = {}) {
            const props = usePage().props
            var translation = getValue(props.messages, key)
                ? getValue(props.messages, key)
                : key

            Object.keys(replace).forEach(function (key) {
                translation = translation.replace(':' + key, replace[key])
            });

            return translation
        },

        /**
         * Translate the given key with basic pluralization.
         */
        __n(key, number, replace = {}) {
            var options = key.split('|');

            key = options[1];
            if(number == 1) {
                key = options[0];
            }

            return tt(key, replace);
        },

        formatNumber(number, decimal = 2) {
            if (number && Number.isNaN(number)) {
                console.error('Give me a number !!!')
                return '0'
            } else if (!number) {
                return '0'
            }
            number = Number(number).toFixed(decimal)
            // remove sign if negative
            let sign = 1;
            if (number < 0) {
                sign = -1;
                number = -number;
            }
            // trim the number decimal point if it exists
            let num = number.toString().includes('.') ? number.toString().split('.')[0] : number.toString();
            while (/(\d+)(\d{3})/.test(num.toString())) {
                // insert comma to 4th last position to the match number
                num = num.toString().replace(/(\d+)(\d{3})/, '$1' + '\'' + '$2');
            }
            // add number after decimal point
            if (number.toString().includes('.')) {
                num = num + '.' + number.toString().split('.')[1];
            }
            // return result with - sign if negative
            return sign < 0 ? '-' + num : num;
        },

        formatHour(hourString) {
            if (hourString) {
                return `${hourString.replace(':', '.')}h`
            }
            return hourString
        }
    },
}

function getValue(object, path) {

    // Break input path into parts separated by '.' via split
    const parts = path.split(".");

    // Iterate the parts of the path, and reduce that parts array to a single
    // value that we incrementally search for by current key, from a prior
    // nested "value"
    return parts.reduce((value, key) => value ? value[key] : value, object)
}
